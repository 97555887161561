<template>
  <div class="pa-4">
    <!-- mapa -->
    <template v-if="!trip.is_multi_stop">
      <v-row dense>
        <v-col cols="12">
          <v-card class="pa-5" :min-height="computedHeight">
            <v-row dense>
              <v-col
                cols="12"
                md="6"
                class="
                  d-flex
                  flex-row
                  justify-md-start justify-center
                  align-center
                "
              >
                <span class="d-flex flex-row justify-start align-center">
                  <h2>
                    {{
                      trip.shipment
                        ? $t("parcel_view_map_title")
                        : $t("Trip map")
                    }}
                  </h2>
                  <v-tooltip
                    top
                    content-class="bs-tooltip-top arrow"
                    :max-width="300"
                  >
                    <template #activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        medium
                        v-on="on"
                        class="ml-6 color-gray-text rounded-0"
                      >
                        {{ icons.mdiInformationOutline }}
                      </v-icon>
                    </template>
                    <span v-if="trip.status === 'Completed'">
                      <!-- <ul> -->
                      <!-- <li> -->
                      {{
                        trip.shipment
                          ? $t("parcel_view_map_completed_tooltip")
                          : $t("tooltipTripMap")
                      }}
                      <!-- </li> -->
                      <!-- </ul> -->
                    </span>
                    <span v-else>
                      <!-- <ul> -->
                      <!-- <li> -->
                      {{
                        trip.shipment
                          ? $t("parcel_view_map_completed_tooltip")
                          : $t("tooltipTripMap2")
                      }}
                      <!-- </li> -->
                      <!-- </ul> -->
                    </span>
                  </v-tooltip>
                  <!-- <span class="ml-4" v-if="trip.status !== 'Completed'">
                    <v-tooltip top :max-width="300">
                      <template #activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          color="primary"
                          @click="getViewMap()"
                        >
                          <v-icon>
                            {{ icons.mdiRefresh }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ $t("Refresh map") }}
                      </span>
                    </v-tooltip>
                  </span> -->
                </span>
                <span class="ml-4" v-if="trip.shipment">
                  <a href="/parcel-guide" target="_blank" @click.stop>
                    Políticas
                  </a>
                </span>
                <span class="ml-4">
                  <a :href="trip.urlGoogleMaps" target="_blank" @click.stop>
                    Ver en google maps
                  </a>
                </span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <GoogleMap
                  v-if="loaded"
                  :origin="map.origin"
                  :destination="map.destination"
                  :path="map.path"
                  :trip="trip"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-card class="pa-5">
            <v-data-table
              :headers="computedHeaders"
              :loading="table.loading"
              :items="table.trip"
              hide-default-footer
              mobile-breakpoint="600"
              :disable-sort="true"
              :dense="true"
            >
              <template v-slot:header.source_address="{ header }">
                <span class="d-flex flex-row align-center">
                  <v-img
                    :src="
                      trip.shipment
                        ? require('@/views/Parcel/assets/IconOriginMap.png')
                        : require('@/views/RunningTrips/assets/IconOriginMap.png')
                    "
                    style="max-width: 20px"
                  ></v-img>
                  {{ header.text }}
                </span>
              </template>
              <template v-slot:header.destination_address="{ header }">
                <span class="d-flex flex-row align-center">
                  <v-img
                    :src="
                      trip.shipment
                        ? require('@/views/Parcel/assets/IconDestinationMap.png')
                        : require('@/views/RunningTrips/assets/IconDestinationMap.png')
                    "
                    style="max-width: 20px"
                  ></v-img>
                  {{ header.text }}
                </span>
              </template>

              <template #item.user_name="{ item }">
                <a target="_blank" v-if="item.is_corporate_user">
                  {{ item.user_name }}
                </a>
                <span v-else>
                  {{ item.user_name }}
                </span>
                <br />
                <a :href="`tel:${item.user_phone}`">
                  {{ item.user_phone.substring(3) }}
                </a>
              </template>
              <template #item.status="{ item }">
                <v-chip
                  v-if="
                    item.status === 'Waiting' &&
                    !item.server_start_time_for_schedule
                  "
                  class="v-chip-light-bg error--text"
                  color="accent"
                >
                  {{ $t(item.status) }}</v-chip
                >
                <v-chip
                  v-if="
                    item.status === 'Waiting' &&
                    item.server_start_time_for_schedule
                  "
                  class="v-chip-light-bg primary--text"
                  color="primary"
                >
                  {{ $t("Scheduled") }}</v-chip
                >
                <v-chip
                  v-else-if="item.status === 'Accepted'"
                  class="v-chip-light-bg warning--text"
                  color="warning"
                >
                  {{ $t(item.status) }}</v-chip
                >
                <v-chip
                  v-else-if="item.status === 'Coming'"
                  class="v-chip-light-bg warning--text"
                  color="warning"
                >
                  {{ $t(item.status) }}</v-chip
                >
                <v-chip
                  v-else-if="item.status === 'Arrived'"
                  class="v-chip-light-bg success--text"
                  color="success"
                >
                  {{ $t(item.status) }}</v-chip
                >
                <v-chip
                  v-else-if="item.status === 'Started'"
                  class="v-chip-light-bg success--text"
                  color="success"
                >
                  {{ $t(item.status) }}</v-chip
                >
                <v-chip
                  v-else-if="item.status === 'Cancelled by user'"
                  class="v-chip-light-bg error--text"
                  color="error"
                >
                  {{ $t(item.status) }}</v-chip
                >
                <v-chip
                  v-else-if="item.status === 'Completed'"
                  class="v-chip-light-bg success--text"
                  color="success"
                >
                  {{ $t(item.status) }}
                </v-chip>
                <v-chip v-else>
                  {{ $t(item.status) }}
                </v-chip>
                <!-- <v-chip v-else>
            {{ $t(item.trip_status) }}
          </v-chip> -->
              </template>
              <template #item.provider_name="{ value }">
                <!-- <span> {{ $moment(value).locale("es").format("LLLL") }}</span> -->
                <span v-if="!value">{{ $t("No driver") }}</span>
                <span v-else>
                  <a target="_blank">
                    {{ value }}
                  </a>
                </span>
              </template>
              <template #item.fixed_price="{ value }">
                {{ value | currency }}
              </template>
              <template #item.date="{ item }">
                <td>
                  <span v-if="!item.server_start_time_for_schedule">
                    {{ item.created_at | moment("DD-MM-YYYY h:mm a") }}
                  </span>
                  <span v-else>
                    {{
                      item.server_start_time_for_schedule
                        | moment("DD-MM-YYYY h:mm a")
                    }}
                  </span>
                </td>
              </template>

              <template #item.serviceType="{ value }">
                <span
                  class="d-flex flex-column justify-center align-center mt-2"
                  v-if="value"
                >
                  <v-tooltip
                    top
                    content-class="bs-tooltip-top arrow"
                    :max-width="300"
                    bottom
                    :disabled="true"
                  >
                    <template #activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="d-flex flex-column justify-center align-center"
                      >
                        <v-img
                          :src="`${bucketUrl}${value.type_image_url}`"
                          max-width="60"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row> </template
                        ></v-img>
                        <span class="fontUrbanist font-weight-bold">{{
                          value.typename
                        }}</span>
                      </span>
                    </template>
                    <span class="d-flex flex-row justify-center align-center">
                      <v-icon
                        x-small
                        :style="
                          !$vuetify.theme.isDark && 'color: #ffffff !important;'
                        "
                      >
                        {{ icons.mdiAccount }}
                      </v-icon>
                      <span class="ml-2">
                        {{ value.max_space }}
                      </span>
                      <span class="ml-2"> | </span>
                      <v-icon
                        x-small
                        class="ml-2"
                        :style="
                          !$vuetify.theme.isDark && 'color: #ffffff !important;'
                        "
                      >
                        {{ icons.mdiBagSuitcase }}
                      </v-icon>
                      <span class="ml-2">
                        {{ value.baggages }}
                      </span>
                    </span>
                  </v-tooltip>
                </span>
                <span v-else>
                  <!-- <v-progress-circular indeterminate /> -->
                </span>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="d-flex" v-if="trip.shipment">
        <v-col cols="12" md="12">
          <v-card class="pa-4">
            <v-row>
              <v-col cols="12" class="d-flex justify-space-between">
                <h2 class="ml-3">{{ $t("parcel_details") }}</h2>
                <a
                  :href="`https://api.whatsapp.com/send?phone=+58${trip.shipment.responsible_phone}&text=Hola, %20te%20estoy%20enviando%20%20por%20Ridery`"
                  target="_blank"
                >
                  <v-icon>{{ icons.mdiWhatsapp }}</v-icon>
                </a>
              </v-col>
            </v-row>
            <v-list>
              <v-list-item three-line>
                <v-row class="d-flex flex-row align-stretch">
                  <!-- origen -->
                  <template>
                    <v-col
                      cols="12"
                      md="4"
                      class="px-10"
                      :style="
                        $vuetify.breakpoint.mdAndUp
                          ? 'border-right: 1px solid #898989'
                          : 'border-bottom: 1px solid #898989'
                      "
                    >
                      <v-row>
                        <v-col
                          cols="12"
                          class="d-flex flex-row align-start justify-center"
                        >
                          <v-img
                            :src="
                              require('@/views/Parcel/assets/IconOriginMapMap.png')
                            "
                            contain
                            max-width="40"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                        <v-col cols="12" class="d-flex flex-column align-start">
                          <!-- origen -->
                          <span class="text-justify">
                            <span class="font-weight-bold success--text"
                              >Origen:
                            </span>
                            <span>{{ trip.source_address }}</span>
                          </span>
                          <span class="font-weight-bold success--text"
                            >Persona que envía:</span
                          >
                          <span>
                            {{
                              trip.shipment.is_user_sender
                                ? trip.user_name
                                : trip.shipment.responsible_full_name
                            }}
                          </span>
                          <span>
                            <span class="font-weight-bold success--text"
                              >Teléfono:</span
                            >
                            <span v-if="trip.shipment.is_user_sender">
                              <a :href="`tel:${trip.user_phone}`">
                                {{ trip.user_phone.substring(3) }}
                              </a>
                            </span>
                            <span v-else>
                              <a
                                :href="`tel:${trip.shipment.responsible_phone}`"
                              >
                                {{ trip.shipment.responsible_phone }}
                              </a>
                            </span>
                          </span>
                          <span class="text-justify">
                            <span class="font-weight-bold success--text"
                              >Instrucciones en el origen:</span
                            >
                            <span
                              class="text-justify"
                              style="white-space: pre-wrap"
                            >
                              {{ trip.shipment.source_instructions }}
                            </span>
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </template>
                  <!-- destino -->
                  <template>
                    <v-col
                      cols="12"
                      md="4"
                      class="px-10"
                      :style="
                        $vuetify.breakpoint.mdAndUp
                          ? 'border-right: 1px solid #898989'
                          : 'border-bottom: 1px solid #898989'
                      "
                    >
                      <v-row>
                        <v-col
                          cols="12"
                          class="d-flex flex-row align-start justify-center"
                        >
                          <v-img
                            :src="
                              require('@/views/Parcel/assets/IconDestinationMapMap.png')
                            "
                            contain
                            max-width="40"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                        <v-col cols="12" class="d-flex flex-column align-start">
                          <!-- origen -->
                          <span class="text-justify">
                            <span class="font-weight-bold error--text"
                              >Destino:
                            </span>
                            <span>{{ trip.destination_address }}</span>
                          </span>
                          <span class="font-weight-bold error--text"
                            >Persona que recibe:</span
                          >
                          <span>
                            {{
                              !trip.shipment.is_user_sender
                                ? trip.user_name
                                : trip.shipment.responsible_full_name
                            }}
                          </span>
                          <span>
                            <span class="font-weight-bold error--text"
                              >Teléfono:</span
                            >
                            <span v-if="!trip.shipment.is_user_sender">
                              <a :href="`tel:${trip.user_phone}`">
                                {{ trip.user_phone.substring(3) }}
                              </a>
                            </span>
                            <span v-else>
                              <a
                                :href="`tel:${trip.shipment.responsible_phone}`"
                              >
                                {{ trip.shipment.responsible_phone }}
                              </a>
                            </span>
                          </span>
                          <span class="text-justify">
                            <span class="font-weight-bold error--text"
                              >Instrucciones en el destino:</span
                            >
                            <span
                              class="text-justify"
                              style="white-space: pre-wrap"
                            >
                              {{ trip.shipment.destination_instructions }}
                            </span>
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </template>
                  <!-- destino -->
                  <template>
                    <v-col
                      cols="12"
                      md="4"
                      class="d-flex flex-column justify-center px-10"
                    >
                      <span class="text--primary fontUrbanist font-weight-bold">
                        Foto del paquete
                      </span>
                      <span class="d-flex justify-center mt-2">
                        <v-dialog
                          v-if="trip.shipment.user_picture"
                          v-model="dialogPackagePhoto"
                          fullscreen
                          transition="dialog-bottom-transition"
                          scrollable
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-fade-transition mode="out-in">
                              <v-img
                                :src="trip.shipment.user_picture"
                                width="70"
                                height="70"
                                v-bind="attrs"
                                v-on="on"
                                class="hover-image rounded-lg"
                                contain
                              >
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular
                                      indeterminate
                                      color="primary"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-fade-transition>
                          </template>
                          <v-card class="testcard" style="overflow-y: auto">
                            <v-row dense>
                              <v-col cols="12" class="">
                                <v-toolbar
                                  color="primary"
                                  class="d-flex justify-space-between"
                                  tile
                                >
                                  <span
                                    class="
                                      d-flex
                                      flex-row
                                      justify-space-between
                                    "
                                    style="width: 98vw"
                                  >
                                    <v-toolbar-title class="color-white-text"
                                      >Foto del paquete</v-toolbar-title
                                    >
                                    <v-btn
                                      icon
                                      dark
                                      @click="dialogPackagePhoto = false"
                                    >
                                      <v-icon>{{ icons.mdiClose }}</v-icon>
                                    </v-btn>
                                  </span>
                                </v-toolbar>
                              </v-col>
                            </v-row>
                            <v-row dense>
                              <v-col
                                cols="12"
                                class="d-flex align-start justify-center pa-12"
                              >
                                <v-img
                                  :src="trip.shipment.user_picture"
                                  class="modal-image"
                                  contain
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="primary"
                                      ></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-dialog>
                      </span>
                      <span class="text-center mt-2">
                        <span
                          class="font-weight-bold primary--text text-caption"
                          >Descripción del paquete:</span
                        >
                        <br />
                        <span
                          class="text-justify"
                          style="white-space: pre-wrap"
                        >
                          {{ trip.shipment.package_description }}
                        </span>
                      </span>
                      <template v-if="trip.shipment.provider_picture">
                        <v-divider color="#898989"></v-divider>
                        <span
                          class="
                            text--primary
                            fontUrbanist
                            font-weight-bold
                            mt-2
                          "
                        >
                          Foto de la encomienda (conductor)
                        </span>
                        <span class="d-flex justify-center mt-2">
                          <v-dialog
                            v-if="trip.shipment.provider_picture"
                            v-model="dialogPackagePhotoDriver"
                            fullscreen
                            transition="dialog-bottom-transition"
                            scrollable
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-fade-transition mode="out-in">
                                <v-img
                                  :src="trip.shipment.provider_picture"
                                  width="70"
                                  height="70"
                                  v-bind="attrs"
                                  v-on="on"
                                  class="hover-image rounded-lg"
                                  contain
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="primary"
                                      ></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-fade-transition>
                            </template>
                            <v-card class="testcard" style="overflow-y: auto">
                              <v-row dense>
                                <v-col cols="12" class="">
                                  <v-toolbar
                                    color="primary"
                                    class="d-flex justify-space-between"
                                    tile
                                  >
                                    <span
                                      class="
                                        d-flex
                                        flex-row
                                        justify-space-between
                                      "
                                      style="width: 98vw"
                                    >
                                      <v-toolbar-title class="color-white-text"
                                        >Foto del paquete</v-toolbar-title
                                      >
                                      <v-btn
                                        icon
                                        dark
                                        @click="
                                          dialogPackagePhotoDriver = false
                                        "
                                      >
                                        <v-icon>{{ icons.mdiClose }}</v-icon>
                                      </v-btn>
                                    </span>
                                  </v-toolbar>
                                </v-col>
                              </v-row>
                              <v-row dense>
                                <v-col
                                  cols="12"
                                  class="
                                    d-flex
                                    align-start
                                    justify-center
                                    pa-12
                                  "
                                >
                                  <v-img
                                    :src="trip.shipment.provider_picture"
                                    class="modal-image"
                                    contain
                                  >
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                      >
                                        <v-progress-circular
                                          indeterminate
                                          color="primary"
                                        ></v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-dialog>
                        </span>
                      </template>
                    </v-col>
                  </template>
                </v-row>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </template>

    <!-- multiparadas viajes -->
    <template v-if="trip.is_multi_stop">
      <v-row dense>
        <v-col>
          <v-card class="pa-5" :min-height="computedHeight">
            <v-row dense>
              <v-col
                cols="12"
                md="6"
                class="
                  d-flex
                  flex-row
                  justify-md-start justify-center
                  align-center
                "
              >
                <span class="d-flex flex-row justify-start align-center">
                  <h2>
                    {{ $t("multistop_map_view") }}
                  </h2>
                  <v-tooltip
                    top
                    content-class="bs-tooltip-top arrow"
                    :max-width="300"
                  >
                    <template #activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        medium
                        v-on="on"
                        class="ml-6 color-gray-text rounded-0"
                      >
                        {{ icons.mdiInformationOutline }}
                      </v-icon>
                    </template>
                    <span v-if="trip.status === 'Completed'">
                      <!-- <ul> -->
                      <!-- <li> -->
                      {{ $t("tooltipTripMap") }}
                      <!-- </li> -->
                      <!-- </ul> -->
                    </span>
                    <span v-else>
                      <!-- <ul> -->
                      <!-- <li> -->
                      {{ $t("tooltipTripMap2") }}
                      <!-- </li> -->
                      <!-- </ul> -->
                    </span>
                  </v-tooltip>
                  <!-- <span class="ml-4" v-if="trip.status !== 'Completed'">
                    <v-tooltip top :max-width="300">
                      <template #activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          color="primary"
                          @click="getViewMap()"
                        >
                          <v-icon>
                            {{ icons.mdiRefresh }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ $t("Refresh map") }}
                      </span>
                    </v-tooltip>
                  </span> -->
                </span>
                <span class="ml-4" v-if="trip.is_shipment">
                  <a href="/parcel-guide" target="_blank" @click.stop>
                    Políticas
                  </a>
                </span>
                <span class="ml-4">
                  <a :href="trip.urlGoogleMaps" target="_blank" @click.stop>
                    Ver en google maps
                  </a>
                </span>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <GoogleMapShowMulti
                  v-if="loaded"
                  :origin="map.stops[0]"
                  :stops="map.stops"
                  :viewMap="true"
                  :trip="trip"
                  :dataProp="dataProp"
                  :parcel="trip.is_shipment"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <DataTableMulti
            :headers="
              trip.is_shipment ? tableMulti.headersParcel : tableMulti.headers
            "
            :loading="table.tableLoading"
            :items="tableMulti.items"
            :showPage="true"
            :parcel="trip.is_shipment"
          />
        </v-col>
      </v-row>
      <br />
      <br />
      <v-row dense>
        <v-col
          cols="12"
          md="12"
          class="d-flex flex-row justify-md-start justify-center"
        >
          <span class="d-flex flex-row justify-start align-center">
            <h2>
              {{ $t("multistop_total_resume") }}
            </h2>
            <v-tooltip top :max-width="300">
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  medium
                  v-on="on"
                  class="ml-6 color-gray-text rounded-0"
                >
                  {{ icons.mdiInformationOutline }}
                </v-icon>
              </template>
              <span>
                <!-- <ul> -->
                <!-- <li> -->
                {{ $t("multistop_total_resume_tooltip") }}
                <!-- </li> -->
                <!-- </ul> -->
              </span>
            </v-tooltip>
          </span>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col v-if="!table.tableLoading" cols="12">
          <DataTableTotalMulti
            :headers="tableTotalMulti.headers"
            :items="computedTableTotal"
            :showPage="true"
            :parcel="trip.is_shipment"
          />
        </v-col>
        <v-col
          cols="12"
          v-else
          class="d-flex flex-row justify-center align-center"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import {
  mdiInformationOutline,
  mdiArrowLeft,
  mdiRefresh,
  mdiAccount,
  mdiBagSuitcase,
  mdiWhatsapp,
} from "@mdi/js";
import GoogleMap from "./parts/GoogleMap.vue";
import { mapActions, mapState } from "vuex";
import axios from "@/plugins/axios";
import GoogleMapShowMulti from "@/views/Multistop/actions/parts/GoogleMapShow";
import DataTableMulti from "@/views/Multistop/actions/parts/DataTable.vue";
import DataTableTotalMulti from "@/views/Multistop/actions/parts/DataTableTotal.vue";

export default {
  components: {
    GoogleMap,
    GoogleMapShowMulti,
    DataTableMulti,
    DataTableTotalMulti,
  },
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiArrowLeft,
        mdiRefresh,
        mdiAccount,
        mdiBagSuitcase,
        mdiWhatsapp,
      },
      table: {
        trip: [],
        tableLoading: false,
        headers: [
          { text: "ID VIAJE", value: "unique_id", align: "start" },
          { text: "USUARIO", value: "user_name", align: "start" },
          { text: "CONDUCTOR", value: "provider_name", align: "start" },
          { text: "FECHA Y HORA", value: "date", align: "start", width: 180 },
          { text: "COSTO", value: "fixed_price", align: "end" },
          { text: "SERVICIO", value: "serviceType", align: "center" },
          {
            text: "DIRECCIÓN DE ORIGEN",
            value: "source_address",
            align: "start",
          },
          {
            text: "DIRECCIÓN DE DESTINO",
            value: "destination_address",
            align: "start",
          },
          { text: "STATUS", value: "status", align: "center" },
          // {
          //   text: "ACCIÓN",
          //   value: "actions",
          //   sortable: false,
          //   align: "center",
          // },
        ],
      },
      map: {
        origin: {},
        destination: {},
        path: [],
        stops: [],
      },
      tableMulti: {
        headers: [
          { text: "PARADA", value: "stopNumber", align: "center" },
          { text: "DIRECCIÓN DE ORIGEN", value: "stopOrigin", align: "start" },
          {
            text: "DIRECCIÓN DE DESTINO",
            value: "stopDestination",
            align: "start",
          },
          {
            text: "TIEMPO ESTIMADO",
            value: "stopTime",
            align: "end",
          },
          {
            text: "DISTANCIA ESTIMADA",
            value: "stopDistance",
            align: "end",
          },
          {
            text: "COSTO PARADA",
            value: "costStop",
            align: "end",
          },
          {
            text: "RECARGO",
            value: "costFee",
            align: "end",
          },
          {
            text: "TOTAL PARADA",
            value: "costTotal",
            align: "end",
          },
        ],
        headersParcel: [
          { text: "PARADA", value: "stopNumber", align: "center" },
          { text: "DIRECCIÓN DE ORIGEN", value: "stopOrigin", align: "start" },
          {
            text: "DIRECCIÓN DE DESTINO",
            value: "stopDestination",
            align: "start",
          },
          {
            text: "TIEMPO ESTIMADO",
            value: "stopTime",
            align: "end",
          },
          {
            text: "DISTANCIA ESTIMADA",
            value: "stopDistance",
            align: "end",
          },
          {
            text: "COSTO PARADA",
            value: "costStop",
            align: "end",
          },
          {
            text: "RECARGO",
            value: "costFee",
            align: "end",
          },
          {
            text: "TOTAL PARADA",
            value: "costTotal",
            align: "end",
          },
          { text: "", value: "data-table-expand" },
        ],
        items: [],
      },
      tableTotalMulti: {
        headers: [
          { text: "CANTIDAD PARADAS", value: "stops", align: "center" },
          {
            text: "TIEMPO ESTIMADO",
            value: "stopTime",
            align: "center",
          },
          {
            text: "DISTANCIA ESTIMADA",
            value: "stopDistance",
            align: "center",
          },
          {
            text: "COSTO MULTIPARADA",
            value: "costStop",
            align: "center",
          },
          { text: "FECHA Y HORA", value: "date", align: "start", width: 180 },
          { text: "USUARIO", value: "user_name", align: "center" },
          { text: "CONDUCTOR", value: "provider_name", align: "center" },
          {
            text: "SERVICIO",
            value: "serviceType",
            align: "center",
          },
          { text: "STATUS", value: "status", align: "center" },
        ],
      },
      loaded: false,
      trip: {},
      dataProp: {},
      bucketUrl: process.env.VUE_APP_IMAGE_BUCKET,
      dialogPackagePhoto: false,
      dialogPackagePhotoDriver: false,
    };
  },
  computed: {
    computedHeight() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return this.formOpen ? "800px" : "550px";
      } else {
        return "400px";
      }
    },
    computedMapOrigin() {
      return this.map.origin;
    },
    computedHeaders() {
      let headers = this.table.headers;
      headers.map((head) => (head.text = this.$t(head.text)));
      return headers;
    },
    computedTableTotal() {
      const { serviceType } = this.trip;
      const { user } = this.trip;
      return [
        {
          stops: this.tableMulti.items.length,
          stopTime:
            this.tableMulti.items.reduce((accumulator, object) => {
              return accumulator + object.stopTimeOnly;
            }, 0) + "m",
          stopDistance:
            parseFloat(
              this.tableMulti.items.reduce((accumulator, object) => {
                return accumulator + object.stopDistanceOnly;
              }, 0)
            ).toFixed(2) + "Km",
          costStop: this.$options.filters.currency(
            this.tableMulti.items.reduce((accumulator, object) => {
              return accumulator + object.costTotalOnly;
            }, 0)
          ),
          date: this.trip.server_start_time_for_schedule,
          serviceType: {
            image: serviceType.type_image_url,
            type: serviceType.typename,
            capacity: serviceType.max_space,
            baggage: serviceType.baggages,
          },
          user_name: user.first_name + " " + user.last_name,
          user_phone: user.phone,
          trip: this.trip,
        },
      ];
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getTrip() {
      this.setLoading(true);
      this.table.loading = true;
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_STAGING}/trip_shipment_show/${this.$route.params.id}`
        );
        if (!!data.success) {
          if (!data.trip.is_multi_stop) {
            this.table.trip = [];
            data.trip["is_corporate_user"] = data.trip.is_corporate_user;
            data.trip["user_phone"] = data.trip.user_phone;
            data.trip["user_name"] =
              data.trip.user_first_name + " " + data.trip.user_last_name;
            data.trip["provider_name"] =
              data.trip.provider_first_name &&
              data.trip.provider_first_name +
                " " +
                data.trip.provider_last_name;
            data.trip["status"] = data.status;
            this.table.trip.push(data.trip);
            this.trip = data.trip;
            this.map.origin["lat"] =
              data.tripLocation.startTripLocation[0] === 0
                ? data.trip.sourceLocation[0]
                : data.tripLocation.startTripLocation[0];
            this.map.origin["lng"] =
              data.tripLocation.startTripLocation[1] === 0
                ? data.trip.sourceLocation[1]
                : data.tripLocation.startTripLocation[1];
            this.map.destination["lat"] =
              data.tripLocation.endTripLocation[0] === 0
                ? data.trip.destinationLocation[0]
                : data.tripLocation.endTripLocation[0];
            this.map.destination["lng"] =
              data.tripLocation.endTripLocation[1] === 0
                ? data.trip.destinationLocation[1]
                : data.tripLocation.endTripLocation[1];
            if (data.tripLocation.startTripLocation[0] !== 0) {
              data.tripLocation.startTripToEndTripLocations.forEach((coord) => {
                this.map.path.push({
                  lat: parseFloat(coord[0]),
                  lng: parseFloat(coord[1]),
                });
              });
            }
          }

          if (data.trip.is_multi_stop) {
            this.dataProp = data;
            this.tableMulti.items = [];
            this.map.stops = [];
            const { trip } = data;
            this.trip = trip;
            const { routes } = trip;
            //origen
            this.map.stops.push({
              lat: routes[0].sourceLocation[0],
              lng: routes[0].sourceLocation[1],
            });
            //paradas
            routes.forEach((route) => {
              this.map.stops.push({
                lat: route.destinationLocation[0],
                lng: route.destinationLocation[1],
              });
            });
            //table items
            this.tableMulti.items = new Array(routes.length);
            if (!data.trip.is_shipment) {
              routes.forEach((stop, index) => {
                this.tableMulti.items[index] = {
                  stopNumber: index + 1,
                  stopOrigin: stop.source_address,
                  stopDestination: stop.destination_address,
                  stopTime: Math.ceil(stop.time) + "m",
                  stopTimeOnly: Math.ceil(stop.time),
                  stopDistance: stop.distance + "Km",
                  stopDistanceOnly: stop.distance * 1,
                  costStop: this.$options.filters.currency(stop.base_estimated),
                  costStopOnly: stop.base_estimated,
                  costFee: this.$options.filters.currency(stop.multi_stops_fee),
                  costTotal: this.$options.filters.currency(
                    stop.estimated_fare
                  ),
                  costTotalOnly: stop.estimated_fare,
                };
              });
            } else {
              routes.forEach((stop, index) => {
                this.tableMulti.items[index] = {
                  stopNumber: index + 1,
                  stopOrigin: stop.source_address,
                  stopDestination: stop.destination_address,
                  stopTime: Math.ceil(stop.time) + "m",
                  stopTimeOnly: Math.ceil(stop.time),
                  stopDistance: stop.distance + "Km",
                  stopDistanceOnly: stop.distance * 1,
                  costStop: this.$options.filters.currency(stop.base_estimated),
                  costStopOnly: stop.base_estimated,
                  costFee: this.$options.filters.currency(stop.multi_stops_fee),
                  costTotal: this.$options.filters.currency(
                    stop.estimated_fare
                  ),
                  costTotalOnly: stop.estimated_fare,
                  shipmentDetails: stop.shipment,
                };
              });
            }
          }
          this.loaded = true;
        } else {
          this.$router.go(-1);
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
        this.$router.go(-1);
      }
      this.table.loading = false;
      this.setLoading(false);
    },
    async getViewMap() {
      await this.getTrip();
    },
  },
  async created() {
    await this.getTrip();
  },
};
</script>
<style lang="scss" scoped>
.modal-image {
  max-width: 70vw;
  max-height: 70vw;
  // max-height: 500px;
  // max-width: 800px;
}

.hover-image:hover {
  cursor: pointer;
}
</style>
